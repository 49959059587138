import ApiService from "@/core/services/ApiService";
import {Actions, Api, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";


@Module
export default class DocumentModule extends VuexModule {
    document = {};
    loaded = false as Boolean;


    get isDocumentReady(): Boolean {
        return this.loaded;
    }

    @Mutation
    [Mutations.SET_DOCUMENT](document) {
        console.info(document);
        this.document = document;
        this.loaded = true;
    }


    @Action
    [Actions.GENERATE_DOCUMENT](data) {
        this.context.commit(Mutations.SET_DOCUMENT, false);
        ApiService.postAsFromData(Api.GENERATE_DOCUMENT, data)
            .then(response => {
                this.context.commit(Mutations.SET_EMPLOYEE, response.data);
            })
            .catch(({response}) => {
                console.error(response);
                if (!isNaN(response)) {
                }
            });

    }





}
