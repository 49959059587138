import {App} from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import {AxiosResponse, AxiosRequestConfig} from "axios";


/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
    /**
     * @description property to share vue instance
     */
    public static vueInstance: App;

    /**
     * @description initialize vue axios
     */
    public static init(app: App<Element>) {
        ApiService.vueInstance = app;
        ApiService.vueInstance.use(VueAxios, axios);
        ApiService.vueInstance.axios.defaults.baseURL = "https://clavis-be.kozel-technologies.cz";
        ApiService.vueInstance.axios.interceptors.request.use(config => {
            console.info("Request:");
            console.info(config);
            return config;
        });
        ApiService.vueInstance.axios.interceptors.response.use((response) => {
            console.info("Response:");
            console.info(response);
            return response;
        });
    }

    /**
     * @description set the default HTTP request headers
     */
    public static setHeader(): void {
        if (JwtService.getToken()) {
            ApiService.vueInstance.axios.defaults.headers.common[
                "Authorization"
                ] = `Bearer ${JwtService.getToken()}`;
        }
    }


    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static query(
        resource: string,
        params: AxiosRequestConfig
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.get(resource, params).catch((error) => {
            throw new Error(`ApiService ${error}`);
        });
    }

    /**
     * @description send the GET HTTP request
     * @param url: string
     * @param params: object
     * @returns Promise<AxiosResponse>
     */
    public static get(url: string, params = {} as {}): Promise<AxiosResponse> {
        if (Object(params).__v_isRef !== true && Object.keys(params).length > 0) {
            url = this.getUrl(params, url);
        }
        ApiService.setHeader();
        return ApiService.vueInstance.axios
            .get(url)
            .catch((error) => {
                throw new Error(`ApiService ${error}`);
            });

    }

    private static getUrl(params: {}, url: string) {
        Object.keys(params).forEach(key => {
            let searchValue = ":" + key + ":";
            if (url.search(searchValue) == -1) {
                console.error("param not found in url string ! " + url);
            } else {
                url = url.replace(searchValue, params[key]);
            }
        });
        return url;
    }

    /**
     * @description set the POST HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static post(
        resource: string,
        params: AxiosRequestConfig
    ): Promise<AxiosResponse> {
        ApiService.setHeader();
        return ApiService.vueInstance.axios.post(`${resource}`, params);
    }

    public static postLogin(
        resource: string,
        data: any
    ): Promise<AxiosResponse> {
        ApiService.setHeader();
        let formData = new FormData();
        formData.append('email', data.email);
        formData.append('password', data.password);


        return ApiService.vueInstance.axios.post(`${resource}`, formData)

    }

    public static postFrom(resource: string, data: any, params = {}): Promise<AxiosResponse> {
        let formData = new FormData();
        let property: keyof typeof data;
        if (Object.keys(params).length > 0) {
            resource = this.getUrl(params, resource);
        }

        for (property in data) {
            if (data[property] !== "") {
                formData.append(property, data[property]);
            }
        }
        return ApiService.vueInstance.axios.post(resource, data)
    }

    public static postAsFromData(resource: string, data: any): Promise<AxiosResponse> {
        let formData = new FormData();
        let property: keyof typeof data;
        console.debug(data);
        for (property in data) {
            if (data[property] !== "") {
                console.log("data[property]");
                console.log(property.toString());
                if (property == 'template_ids') {
                    console.debug("[" + data[property].toString() + "]")
                    formData.append(property, "[" + data[property].toString() + "]");

                } else {
                    formData.append(property, data[property].toString());
                }

            }
        }

        return ApiService
            .vueInstance
            .axios
            .post(resource, formData)
    }


    /**
     * @description send the UPDATE HTTP request
     * @param resource: string
     * @param slug: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static

    update(
        resource
            :
            string,
        slug
            :
            string,
        params
            :
            AxiosRequestConfig
    ):
        Promise<AxiosResponse> {
        ApiService.setHeader();
        return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
    }

    /**
     * @description Send the PUT HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static put(resource: string, params: AxiosRequestConfig):
        Promise<AxiosResponse> {
        ApiService.setHeader();
        return ApiService.vueInstance.axios.put(`${resource}`, params);
    }

    /**
     * @description Send the DELETE HTTP request
     * @param resource: string
     * @returns Promise<AxiosResponse>
     */
    public static delete(resource: string, params = {}):
        Promise<AxiosResponse> {
        console.log(params)
        if (Object.keys(params).length > 0) {
            resource = this.getUrl(params, resource);
        }
        return ApiService.vueInstance.axios.delete(resource).catch((error) => {
            ApiService.setHeader();
            throw new Error(`[KT] ApiService ${error}`);
        });
    }

}

export default ApiService;
