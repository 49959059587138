import ApiService from "@/core/services/ApiService";
import {Actions, Api, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, VuexModule} from "vuex-module-decorators";

export interface IRole {
    id_factory: Number,
    name_isco: String,
    id_isco: Number,
    name_nace: String,
    title: String,
    name_kkov: String,
}

@Module
export default class RoleModule extends VuexModule {
    @Action
    [Actions.ADD_ROLE](data) {
        return new Promise<void>((resolve, reject) => {
            ApiService.postFrom(Api.ADD_ROLE, data)
                .then(() => {
                    resolve();
                })
                .catch(({response}) => {
                    console.error(response)
                    this.context.commit(Mutations.SET_ERROR, response.data.ErrorMessage);
                    reject();
                });
        });
    }

}
