import ApiService from "@/core/services/ApiService";
import {Actions, Api, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {IEmployee} from "@/model/employee";

@Module
export default class EmployeeModule extends VuexModule {
    employee = {} as IEmployee;
    employeeRole = {};
    loaded = false as Boolean;
    loadedRole = false as Boolean;
    empDocuments = {}
    empDocumentReady = false as Boolean

    get currentEmployee(): IEmployee {
        return this.employee;
    }

    get currentRole() {
        return this.employeeRole;
    }

    get isRoleLoaded(): Boolean {
        return this.loadedRole;
    }

    get isEmployeeLoaded(): Boolean {
        return this.loaded;
    }

    get isEmployeeDocumentReady(): Boolean {
        return this.empDocumentReady;
    }
    get getDocumentsForEmployee() {
        return this.empDocuments
    }

    @Mutation
    [Mutations.SET_LOADED_EMPLOYEE](value: boolean) {
        this.loaded = value;
    }

    @Mutation
    [Mutations.SET_EMPLOYEE](employee) {
        console.info(employee);
        this.employee = employee;
        this.loaded = true;
    }

    @Mutation
    [Mutations.SET_EMPLOYEE_ROLE](role) {
        console.info(role);
        this.employeeRole = role;
        this.loadedRole = true;
    }

    @Mutation
    [Mutations.SET_LOADED_EMPLOYEE_ROLE](value: boolean) {
        this.loadedRole = value;
    }

    @Mutation
    [Mutations.SET_EMP_DOCUMENTS](documents) {
        console.info(documents);
        this.empDocuments = documents;
        this.empDocumentReady = true;
    }

    @Action
    [Actions.ADD_EMPLOYEE](data) {
        this.context.commit(Mutations.SET_LOADED_EMPLOYEE, false);
        ApiService.postFrom(Api.ADD_EMPLOYEE, data)
            .then(response => {
                this.context.commit(Mutations.SET_EMPLOYEE, response.data);
                console.info('Uloženo');
            })
            .catch(({response}) => {
                console.error(response);
                if (!isNaN(response)) {
                }
            });

    }

    @Action
    [Actions.GET_EMPLOYEE](employee_id) {
        ApiService.get(Api.GET_EMPLOYEE, {employee_id: employee_id})
            .then(response => {
                this.context.commit(Mutations.SET_EMPLOYEE, response.data);
                ApiService.get(Api.GET_CURRENT_EMPLOYEE_ROLE, {employee_id: employee_id})
                    .then(response => {
                        this.context.commit(Mutations.SET_EMPLOYEE_ROLE, response.data)
                    })
            })
            .catch(({response}) => {
                console.error(response);
                if (!isNaN(response)) {
                }
            });
    }

    @Action
    [Actions.GET_EMP_DOCUMENT](emp_id) {
        ApiService.get(Api.GET_EMPLOYEE_DOCUMENTS, {employee_id: emp_id})
            .then(response => {
                this.context.commit(Mutations.SET_EMP_DOCUMENTS, response.data)
            })
    }

}
