export function castData(data, definition) {
    let castedData = {};
    let type: keyof typeof definition;
    for (type in definition) {
        if (data[type] !== "") {
            if (definition[type] === 'number') {
                castedData[type] = convertToNumber(data[type])
            } else if (definition[type] === 'date') {
                castedData[type] = convertToDate(data[type])
            } else {
                castedData[type] = data[type];
            }
        }
    }
    return castedData;
}

function convertToNumber(value): number {
    return Number(value);
}
function convertToDate(value):Date {
    return new Date(value);
}
