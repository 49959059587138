import {createRouter, createWebHashHistory, RouteRecordRaw} from "vue-router";
import store from "@/store";
import {Mutations, Actions} from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        redirect: "/dashboard",
        component: () => import("@/layout/Layout.vue"),
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                component: () => import("@/views/Dashboard.vue"),
            },
            {
                path: "/employee",
                name: "employee",
                component: () => import("@/views/employee/overview.vue"),
            },
            {
                path: "/employee/overview",
                name: "EmployeeOverview",
                component: () => import("@/views/employee/overview.vue"),
            },
            {
                path: "/employee/add",
                name: "addEmployee",
                component: () => import("@/views/employee/addEmployee.vue"),
            },
            {
                path: "/employee/add/wizard/1",
                name: "addEmployeeStep1",
                component: () => import("@/views/employee/wizard/addEmployee_step1.vue"),
            },
            {
                path: "/employee/add/wizard/2",
                name: "addEmployeeStep2",
                component: () => import("@/views/employee/wizard/addEmployee_step2.vue"),
            },
            {
                path: "/employee/add/wizard/3/:id_user",
                name: "addEmployeeStep3",
                component: () => import("@/views/employee/wizard/addEmployee_step3.vue"),
            },
            {
                path: "/employee/editRole/:id_emp",
                name: "addEmployeeEditRole",
                component: () => import("@/views/employee/EditEmployeeRole.vue"),
            },
            {
                path: "/employee/edit/:id_emp",
                name: "employeeEdit",
                component: () => import("@/views/employee/EditEmployee.vue"),
            },
            {
                path: "/employee/detail/:id_emp",
                name: "EmployeeDetail",
                component: () => import("@/views/employee/detail.vue"),
                children: [
                    {
                        path: "history",
                        name: "employeeHistory",
                        component: () => import("@/views/employee/subpage/history.vue"),
                    },
                    {
                        path: "info",
                        name: "employeeInfo",
                        component: () => import("@/views/employee/subpage/info.vue"),
                    },
                    {
                        path: "documents",
                        name: "employeeDocuments",
                        component: () => import("@/views/employee/subpage/documents.vue"),
                    },
                ],
            },
            {
                path: "/factory",
                name: "factory",
                component: () => import("@/views/factory/overview.vue"),
            },
            {
                path: "/detail/:idFactory",
                name: "factoryDetail",
                redirect: "detail/:idFactory/factory-role",
                component: () => import("@/views/factory/detail.vue"),
                children: [
                    {
                        path: "factory-role",
                        name: "factoryRole",
                        component: () => import("@/views/factory/subpage/roleOverview.vue"),
                    },
                    {
                        path: "factory-employee",
                        name: "factoryEmployee",
                        component: () => import("@/views/factory/subpage/employeeOverview.vue"),
                    },
                    {
                        path: "add-role",
                        name: "addRole",
                        component: () => import("@/views/factory/forms/AddFactoryRole.vue"),
                    },
                ]
            },


            {
                path: "/factory/overview",
                name: "factoryOverview",
                component: () => import("@/views/factory/overview.vue"),
            },
            {
                path: "/test-component",
                name: "componentTest",
                component: () => import("@/views/test/component.vue"),
            },

            {
                path: "/crafted/pages/wizards/horizontal",
                name: "horizontal-wizard",
                component: () => import("@/views/crafted/pages/wizards/Horizontal.vue"),
            },
            {
                path: "/crafted/pages/wizards/vertical",
                name: "vertical-wizard",
                component: () => import("@/views/crafted/pages/wizards/Vertical.vue"),
            },
            {
                path: "/crafted/account",
                name: "account",
                component: () => import("@/views/crafted/account/Account.vue"),
                children: [
                    {
                        path: "overview",
                        name: "account-overview",
                        component: () => import("@/views/crafted/account/Overview.vue"),
                    },
                    {
                        path: "settings",
                        name: "account-settings",
                        component: () => import("@/views/crafted/account/Settings.vue"),
                    },
                ],
            },
            {
                path: "/apps/customers/getting-started",
                name: "apps-customers-getting-started",
                component: () => import("@/views/apps/customers/GettingStarted.vue"),
            },
            {
                path: "/apps/customers/customers-listing",
                name: "apps-customers-listing",
                component: () => import("@/views/apps/customers/CustomersListing.vue"),
            },
            {
                path: "/apps/customers/customer-details",
                name: "apps-customers-details",
                component: () => import("@/views/apps/customers/CustomerDetails.vue"),
            },
            {
                path: "/apps/chat/private-chat",
                name: "apps-private-chat",
                component: () => import("@/views/apps/chat/Chat.vue"),
            },
            {
                path: "/apps/chat/group-chat",
                name: "apps-group-chat",
                component: () => import("@/views/apps/chat/Chat.vue"),
            },
            {
                path: "/apps/chat/drawer-chat",
                name: "apps-drawer-chat",
                component: () => import("@/views/apps/chat/DrawerChat.vue"),
            },
            {
                path: "/crafted/modals/general/invite-friends",
                name: "modals-general-invite-friends",
                component: () =>
                    import("@/views/crafted/modals/general/InviteFriends.vue"),
            },
            {
                path: "/crafted/modals/general/view-user",
                name: "modals-general-view-user",
                component: () => import("@/views/crafted/modals/general/ViewUsers.vue"),
            },
            {
                path: "/crafted/modals/general/upgrade-plan",
                name: "modals-general-upgrade-plan",
                component: () =>
                    import("@/views/crafted/modals/general/UpgradePlan.vue"),
            },
            {
                path: "/crafted/modals/general/share-and-earn",
                name: "modals-general-share-and-earn",
                component: () =>
                    import("@/views/crafted/modals/general/ShareAndEarn.vue"),
            },
            {
                path: "/crafted/modals/forms/new-target",
                name: "modals-forms-new-target",
                component: () => import("@/views/crafted/modals/forms/NewTarget.vue"),
            },
            {
                path: "/crafted/modals/forms/new-card",
                name: "modals-forms-new-card",
                component: () => import("@/views/crafted/modals/forms/NewCard.vue"),
            },
            {
                path: "/crafted/modals/forms/new-address",
                name: "modals-forms-new-address",
                component: () => import("@/views/crafted/modals/forms/NewAddress.vue"),
            },
            {
                path: "/crafted/modals/forms/create-api-key",
                name: "modals-forms-create-api-key",
                component: () =>
                    import("@/views/crafted/modals/forms/CreateApiKey.vue"),
            },
            {
                path: "/crafted/modals/wizards/two-factor-auth",
                name: "modals-wizards-two-factor-auth",
                component: () =>
                    import("@/views/crafted/modals/wizards/TwoFactorAuth.vue"),
            },
            {
                path: "/crafted/modals/wizards/create-app",
                name: "modals-wizards-create-app",
                component: () => import("@/views/crafted/modals/wizards/CreateApp.vue"),
            },
            {
                path: "/crafted/modals/wizards/create-account",
                name: "modals-wizards-create-account",
                component: () =>
                    import("@/views/crafted/modals/wizards/CreateAccount.vue"),
            },
            {
                path: "/crafted/widgets/lists",
                name: "widgets-list",
                component: () => import("@/views/crafted/widgets/Lists.vue"),
            },
            {
                path: "/crafted/widgets/statistics",
                name: "widgets-statistics",
                component: () => import("@/views/crafted/widgets/Statistics.vue"),
            },
            {
                path: "/crafted/widgets/charts",
                name: "widgets-charts",
                component: () => import("@/views/crafted/widgets/Charts.vue"),
            },
            {
                path: "/crafted/widgets/mixed",
                name: "widgets-mixed",
                component: () => import("@/views/crafted/widgets/Mixed.vue"),
            },
            {
                path: "/crafted/widgets/tables",
                name: "widgets-tables",
                component: () => import("@/views/crafted/widgets/Tables.vue"),
            },
            {
                path: "/crafted/widgets/feeds",
                name: "widgets-feeds",
                component: () => import("@/views/crafted/widgets/Feeds.vue"),
            },
        ],
    },
    {
        path: "/",
        component: () => import("@/components/page-layouts/Auth.vue"),
        children: [
            {
                path: "/sign-in",
                name: "sign-in",
                component: () =>
                    import("@/views/auth/SignIn.vue"),
            },
            {
                path: "/sign-up",
                name: "sign-up",
                component: () =>
                    import("@/views/auth/SignUp.vue"),
            },
            {
                path: "/password-reset",
                name: "password-reset",
                component: () =>
                    import("@/views/auth/PasswordReset.vue"),
            },
        ],
    },
    {
        path: "/employee/print/:id_employee",
        name: "printEmployeeCard",
        component: () => import("@/views/employee/special/print.vue"),
    },
    {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
    },
    {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach(() => {
    // reset config to initial state
    store.commit(Mutations.RESET_LAYOUT_CONFIG);
    store.dispatch(Actions.VERIFY_AUTH);


    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);


});

export default router;
