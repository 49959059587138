import ApiService from "@/core/services/ApiService";
import {Actions, Mutations, Api} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";


export interface IFactory {
    id: number,
    company_name: string,
    dic: string,
    address: string,
    work_location: string,
    center_number: number,
    contact_person: string,
    factory_company_name: string,
    factory_number: string,
    ic: string
}


@Module
export default class FactoryModule extends VuexModule {
    errors = [];
    factory = {} as IFactory;
    isLoaded = false;

    get actualFactory(): IFactory {
        return this.factory;
    }

    get isFactoryLoaded(): Boolean {
        return this.isLoaded;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_FACTORY](data) {
        this.factory = data;
        this.isLoaded = true;
    }


    @Action
    [Actions.ADD_FACTORY](data) {

        return new Promise<void>((resolve, reject) => {

            ApiService.postFrom(Api.ADD_FACTORY, data)
                .then(({data}) => {
                    this.context.commit(Mutations.SET_FACTORY, data);
                    resolve();
                })
                .catch(({response}) => {
                    if (response !== NaN) {
                        console.error(response);
                        this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    }
                    reject();
                });
        });
    }

    @Action
    [Actions.GET_FACTORY](id) {
        return new Promise<void>((resolve, reject) => {

            ApiService.get(Api.GET_FACTORY, {'id': id})
                .then(({data}) => {
                    this.context.commit(Mutations.SET_FACTORY, data);
                    resolve();
                })
                .catch(({response}) => {
                    if (response !== NaN) {
                        console.error(response);
                        this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    }
                    reject();
                });
        });
    }
}



