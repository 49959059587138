enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  ADD_FACTORY = "addFactory",
  GET_FACTORY = "getFactory",
  ADD_EMPLOYEE = "addEmployee",
  GET_EMPLOYEE = "getEmployee",
  ADD_ROLE = "addRole",
  ADD_EMPLOYEE_ROLE = "addEmployeeRole",
  GENERATE_DOCUMENT = "generateDocument",
  GET_EMP_DOCUMENT = "getEmpDocuments",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_FACTORY = "setFactory",
  SET_ROLE = "setRole",
  SET_EMPLOYEE = "setEmployee",
  SET_EMPLOYEE_ROLE = "setEmployeeRole",
  SET_LOADED_EMPLOYEE = "setLoadedEmployee",
  SET_ENUM = "setEnum",
  SET_LOADED_EMPLOYEE_ROLE = "setLoadedEmployeeRole",
  SET_ADDED_EMPLOYEE_ROLE = "setAddEmployeeRole",
  SET_DOCUMENT = "setGeneratedDocument",
  SET_EMP_DOCUMENTS = "setEmpDocument"
}
enum Api {
  ALL_EMPLOYEES="/employee/view/all",
  ALL_FACTORIES="/factory/view/all",
  ADD_FACTORY="/factory/add",
  ADD_ROLE="/role/add",
  ADD_EMPLOYEE_ROLE="/role_employee/add",

  ALL_DOCUMENT="/document/all",
  GENERATE_DOCUMENT="/document/generate",
  GET_EMPLOYEE_DOCUMENTS="/document/history/:employee_id:",
  GET_EMPLOYEE_LAST_ID="/employee/get_last_id",
  GET_FACTORY="/factory/view/:id:",
  EDIT_FACTORY="/factory/edit/:id:",
  GET_ROLE_IN_FACTORY="/role/get_for_factory/:factory_id:",
  GET_EMPLOYEE_IN_FACTORY="/factory/get_all_employees/:factory_id:",
  GET_EMPLOYEE_ALL_ROLES="employee/get_roles/:employee_id:",
  GET_CURRENT_EMPLOYEE_ROLE="employee/get_current_role/:employee_id:",
  ADD_EMPLOYEE="/employee/add",
  EDIT_EMPLOYEE="/employee/edit/:employee_id:",
  GET_EMPLOYEE="/employee/view/:employee_id:",
  FIRE_EMPLOYEE="/employee/fire/:employee_id:",
  DELETE_EMPLOYEE="/employee/delete/:employee_id:",
  ADD_NOTE_TO_EMPLOYEE= "employee/edit_note/:employee_id:",
  GET_DOCUMENT="/document/get/:document_id:",
  ENUM="enum/:enum_name:/all",
  EMP_STATS_ALL="/stats/all",
  OVERVIEW_STATS="/stats/overview",
  OVERVIEW_FACTORY_STATS="/stats/overview/:factory_id:",
  ROLE_EMPLOYEE = "/role_employee/update",
  GET_FACTORY_ACTIVE_EXCEL = "/factory/excel/active/:factory_id:"

}

export { Actions, Mutations, Api };
