import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

export interface User {
    name: string;
    surname: string;
    email: string;
    password: string;
    token: string;
}

export interface UserAuthInfo {
    errors: Array<string>;
    user: User;
    isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
    errors = [];
    user = {} as User;
    isAuthenticated = !!JwtService.getToken();

    /**
     * Get current user object
     * @returns User
     */
    get currentUser(): User {
        return this.user;
    }

    /**
     * Verify user authentication
     * @returns boolean
     */
    get isUserAuthenticated(): boolean {
        return this.isAuthenticated;
    }

    get getErrors() {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_AUTH](user) {
        this.isAuthenticated = true;
        this.user = user;
        this.errors = [];
        JwtService.saveToken(this.user.token);
    }

    @Mutation
    [Mutations.SET_USER](user) {
        this.user = user;
    }

    @Mutation
    [Mutations.SET_PASSWORD](password) {
        this.user.password = password;
    }

    @Mutation
    [Mutations.PURGE_AUTH]() {
        console.debug('PRUGE_AUTH');
        this.isAuthenticated = false;
        this.user = {} as User;
        this.errors = [];
        JwtService.destroyToken();
    }

    @Action
    [Actions.LOGIN](credentials) {

        return new Promise<void>((resolve, reject) => {
            ApiService.postLogin("u/login", credentials)
                .then(({data}) => {
                    this.context.commit(Mutations.SET_AUTH, data);
                    resolve();
                })
                .catch(({response}) => {
                    if (response !== NaN) {
                        console.error(response);
                        this.context.commit(Mutations.SET_ERROR, response.data.ErrorMessage);
                    }
                    reject();
                });
        });
    }

    @Action
    [Actions.LOGOUT]() {
        this.context.commit(Mutations.PURGE_AUTH);
    }

    @Action
    [Actions.REGISTER](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("registration", credentials)
                .then(({data}) => {
                    this.context.commit(Mutations.SET_AUTH, data);
                    resolve();
                })
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    reject();
                });
        });
    }

    @Action
    [Actions.FORGOT_PASSWORD](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("forgot_password", payload)
                .then(({data}) => {
                    this.context.commit(Mutations.SET_AUTH, data);
                    resolve();
                })
                .catch(({response}) => {
                    console.log(response.data.errors);
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    reject();
                });
        });
    }

    @Action
    [Actions.VERIFY_AUTH]() {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.get("verify")
                .then(({data}) => {
                })
                .catch(({response}) => {
                    console.log(response);
                    this.context.commit(Mutations.SET_ERROR, response);
                    this.context.commit(Mutations.PURGE_AUTH);
                });
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.UPDATE_USER](payload) {
        ApiService.setHeader();
        return new Promise<void>((resolve, reject) => {
            ApiService.post("update_user", payload)
                .then(({data}) => {
                    this.context.commit(Mutations.SET_USER, data);
                    resolve();
                })
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    reject();
                });
        });
    }
}
