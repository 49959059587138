const rules = {
    work_start_date: [
        {
            required: true,
            message: "Datum nástupu je povinné",
            trigger: "change",
        },
    ],
    work_end_plan_date: [
        {
            required: true,
            message: "Datum přiřazení je povinné",
            trigger: "change",
        },
    ],
};
const data = {
    work_start_date: "",
    work_end_plan_date: "",
    employee_id: "",
    role_id: "",
}

export interface IRoleEmployee {
    work_start_date: string,
    work_end_plan_date: string,
    employee_id: number,
    role_id: number,
}

export const definition = {
    work_start_date: 'date',
    work_end_plan_date: 'date',
    employee_id: 'number',
    role_id: 'number',
}

export {rules, data}

