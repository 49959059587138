import ApiService from "@/core/services/ApiService";
import {Actions, Api, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {IRoleEmployee, definition as RoleDefinition} from "@/model/role_employee";
import {castData} from "@/core/data/data_prepare";

@Module
export default class EmployeeRoleModule extends VuexModule {
    employeeRole = {} as IRoleEmployee;
    loaded = false as Boolean;

    get currentEmployeeRole(): IRoleEmployee {
        return this.employeeRole;
    }

    get isAddEmployeeRoleLoaded(): Boolean {
        return this.loaded;
    }

    @Mutation
    [Mutations.SET_LOADED_EMPLOYEE_ROLE](value: boolean) {
        this.loaded = value;
    }

    @Mutation
    [Mutations.SET_ADDED_EMPLOYEE_ROLE](data) {
        this.employeeRole = data;
        this.loaded = true;
    }

    @Action
    [Actions.ADD_EMPLOYEE_ROLE](data) {
        data = castData(data, RoleDefinition);
        this.context.commit(Mutations.SET_LOADED_EMPLOYEE_ROLE, false);
        ApiService.postFrom(Api.ADD_EMPLOYEE_ROLE, data)
            .then(response => {
                this.context.commit(Mutations.SET_ADDED_EMPLOYEE_ROLE, response.data);
            })
            .catch(({response}) => {
                console.error(response);
                if (!isNaN(response)) {
                }
            });
    }
}
