import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import FactoryModule from "@/store/modules/FactoryModule";
import RoleModule from "@/store/modules/RoleModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import EmployeeModule from "@/store/modules/EmployeeModule";
import EmployeeRoleModule from "@/store/modules/EmployeeRoleModule";
import DocumentModule from "@/store/modules/DocumentModule";
config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    FactoryModule,
    EmployeeModule,
    BreadcrumbsModule,
    ConfigModule,
    RoleModule,
    EmployeeRoleModule,
    DocumentModule
  },
});

export default store;
